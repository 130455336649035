@import '../../scss/variables';

.btn {
  display: inline-flex;
  position: relative;
  margin-top: 1em;
  padding: 0.75rem 0.625rem 0.5rem;
  outline: none;
  border: 2px solid transparent;
  background: none;
  text-decoration: none;
  font: inherit;
  cursor: pointer;
  transition: var(--transition-combined);
  outline-offset: 2px;

  &:focus {
    outline: 2px solid var(--color-accent);
  }
}

.btn__icon {
  display: block;
  margin: auto;
}

.btn__label {
  display: block;
  margin: 0 0.25em;
}

/* variants */

.btn--primary {
  background: var(--color-primary);
  color: var(--color-text-light);
  border-color: var(--color-primary);

  &:hover {
    color: var(--color-text);
    background: transparent;
  }
}

.btn--secondary {
  background: var(--color-accent);
  color: var(--color-text-light);
  border-color: var(--color-accent);

  &:hover {
    color: var(--color-accent);
    background: transparent;
  }
}

.btn--danger {
  background: var(--color-error);
  color: var(--color-text-light);
  border-color: var(--color-error);

  &:hover {
    color: var(--color-error);
    background: transparent;
  }

  &:focus {
    outline-color: var(--color-error);
  }
}

.btn--lg {
  font-size: 3rem;
  line-height: 3.5rem;
  padding: 2.375rem 6rem 1.625rem;

  @include media-breakpoint-down('md') {
    font-size: 2rem;
    line-height: 2rem;
    padding: 1.625rem 2.5rem 1.375rem;
  }
}

/* tiled */

.btn--tiled {
  display: block;
}

/* fill whole available space (width) */

.btn--fill {
  width: 100%;
}

/* raised */

.btn--raised {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* text align */

.btn--align-left {
  justify-content: flex-start;
}

.btn--align-center {
  justify-content: center;
}

.btn--align-right {
  justify-content: flex-end;
}

/* BTN group */

.btn-group {
  display: flex;
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;
}

.btn-group__item > .btn {
  border-radius: 0;
}

.btn-group__item:first-child > .btn {
  border-radius: 0.5em 0 0 0.5em;
}

.btn-group__item:last-child > .btn {
  border-radius: 0 0.5em 0.5em 0;
}
