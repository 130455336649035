@import '../../scss/variables';

/* react select overrides */
.react-select {
  .react-select__control {
    border: none;
    background: none;
    cursor: pointer;

    &--is-focused {
      border: none;
      box-shadow: none;
    }
  }

  @keyframes menuIn {
    0% {
      opacity: 0;
      transform: translateY(-5%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .react-select__menu {
    right: 0;
    width: auto;
    min-width: 100%;
    background-color: var(--color-menu-bg);
    border-radius: 0;
    box-shadow: none;
    transform-origin: top;
    animation: menuIn var(--transition-timing) var(--transition-easing);
  }

  .react-select__value-container {
    padding-left: 0;
  }

  .react-select__option {
    padding: 0.5rem 1rem;
    color: var(--color-text-light);
    background: none;
    transition: var(--transition-combined);
    cursor: pointer;
    white-space: nowrap;

    &--is-focused,
    &--is-selected {
      color: var(--color-accent);
    }
  }
}

.react-select__value-container input {
  border: none !important;
}

.react-select__single-value {
  position: relative !important;
  transform: none !important;
  max-width: none !important;

  @include media-breakpoint-down('sm') {
    font-size: 0.875rem;
  }
}

.react-select__single-value {
  transition: var(--transition-combined);
}

.react-select--light .react-select__single-value {
  color: var(--color-text-light);
}

.react-select--dark .react-select__single-value {
  color: var(--color-text);
}

.react-select__control:hover .react-select__single-value,
.react-select__control--is-focused .react-select__single-value {
  color: var(--color-accent);
}

.react-select__menu .react-select__menu-list {
  max-height: 25rem;
}

/* custom react-select styles */
.react-select__icon {
  width: 1.5rem;
  height: 0.6rem;
  transform: scaleY(-1);
  color: var(--color-accent);
  transition: var(--transition-combined);

  .react-select__control--menu-is-open & {
    transform: scaleY(1);
  }
}
