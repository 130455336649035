@import '../../scss/variables';

.title-block {
  margin: -6rem 0 0;
  padding-top: 6rem;

  @include pattern-background();

  @include media-breakpoint-down('sm') {
    margin-top: -7rem;
    padding-top: 7rem;
  }

  @include media-breakpoint-up('xl') {
    padding-top: 12rem;
  }
}

.title-block__inner {
  min-height: 0;

  @media (min-width: 1025px) {
    min-height: calc(50vw - 4rem);
  }

  @include media-breakpoint-up('xl') {
    min-height: 45rem;
    max-width: 120rem;
  }
}

.title-block__section {
  margin-top: 1rem;

  @include media-breakpoint-down('sm') {
    margin-top: 0;
  }

  @include media-breakpoint-up('xl') {
    margin-top: 0;
  }
}

.title-block__content {
  display: flex;
  flex-direction: column;
  width: calc(58% + 0.5rem);
  padding-bottom: 2.5rem;

  @include media-breakpoint-down('lg') {
    width: calc(50% + 0.5rem);
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding-bottom: 1rem;
  }
}

.title-block__story {
  z-index: 9;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 1.5rem;
  max-width: 119rem;
  margin: 0 auto;
  padding: 0 2.5rem;
  text-align: right;
  pointer-events: none;

  @include media-breakpoint-down('sm') {
    bottom: 0;
    padding: 0;
  }
}

.title-block__story-button {
  display: inline-flex;
  align-items: center;
  margin-right: -0.5rem;
  padding: 0.5rem 0.5em 0.25rem;
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--color-primary);
  text-decoration: none;
  background: rgba(var(--color-bg-rgb), 0.9);
  pointer-events: auto;

  @include media-breakpoint-down('sm') {
    margin-right: 0;
    color: var(--color-text-light);
    background: var(--color-accent);

    &:hover,
    &:focus {
      color: var(--color-text);
    }
  }
}

.title-block__story-icon {
  width: 2.25rem;
  height: auto;
  margin-right: 0.5rem;
  margin-bottom: 0.1rem;
}

.title-block__intro {
  margin-top: 1em;

  *:first-child {
    margin-top: 0;
  }
}

.title-block__image {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(50vw - 4rem);
  max-height: 100%;
  overflow: hidden;

  &--error {
    display: none;
  }

  @media (max-width: 1024px) {
    position: relative;
    width: 100%;
    margin-top: 2rem;
  }

  @include media-breakpoint-up('xl') {
    width: calc(42% - 4rem);
  }

  &:before {
    display: block;
    height: 0;
    padding-bottom: 100%;
    content: '';
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
}
