@font-face {
  font-family: Aestii;
  src: url('fonts/Aestii-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Aestii;
  src: url('fonts/Aestii-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Aestii;
  src: url('fonts/Aestii-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Aestii;
  src: url('fonts/Aestii-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: IBM Plex Sans;
  src: url('fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

h1,
.h1 {
  font-size: 5rem;
  line-height: 6rem;
  font-weight: 400;

  @include media-breakpoint-down('lg') {
    margin-top: 0.7em;
    font-size: 4rem;
    line-height: 5rem;
  }

  @include media-breakpoint-down('sm') {
    margin-top: 0.7em;
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

h2,
.h2 {
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 400;

  @include media-breakpoint-down('sm') {
    font-size: 2rem;
    line-height: 2rem;
  }
}

h3,
.h3 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 400;

  @include media-breakpoint-down('sm') {
    font-size: 1.75rem;
    line-height: 2rem;
  }
}

h4,
.h4 {
  font-size: 1.375rem;
  line-height: 2rem;
  font-weight: 500;
}

.text--intro {
  font-size: 1.625rem;
  line-height: 2.5rem;

  @include media-breakpoint-down('lg') {
    font-size: 1.375rem;
    line-height: 2rem;
  }
}

.text--reference,
.text--sub {
  font-size: 1.125rem;
  line-height: 1.5rem;

  @include media-breakpoint-down('sm') {
    font-size: 0.875rem;
  }
}

label,
figcaption,
.text--small {
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--color-caption);
  text-align: left;
}

.text--credit {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
  color: var(--color-caption);
}

sup {
  margin-left: -0.25rem;
  font-family: var(--font-family-superscript);
  font-size: 0.5em;
  line-height: 1;

  a {
    text-decoration: none;
  }
}

abbr {
  margin-left: 0.25em;
  text-decoration: none;
  font-size: 0.75rem;
  font-weight: 500;
}

.text--inverted {
  color: var(--color-text-light);
}

.text--break {
  word-wrap: break-word;
}

.text--error {
  color: var(--color-error);
}
