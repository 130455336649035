@mixin pattern-background() {
  position: relative;

  &:before,
  &:after {
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-repeat: repeat;
    opacity: 0.6;
    content: '';
  }

  &:before {
    background-image: url('../../assets/pattern_h.svg');
    background-position: 0 0;
  }

  &:after {
    background-image: url('../../assets/pattern_v.svg');
    background-position: -144px -75px;
  }
}

@mixin space-out($min, $max) {
  @for $i from $min through $max {
    &:first-child:nth-last-child(#{$i}),
    &:first-child:nth-last-child(#{$i}) ~ & {
      width: 1 / $i * 100%;
    }
  }
}
