@import '../../scss/variables';
@import '../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss';

.lightbox__title {
  @include media-breakpoint-down('sm') {
    // TODO check if this actually works
    @media (orientation: landscape) {
      display: none;
    }
  }
}

/* IMAGE GALLERY OVERRIDES */

.image-gallery-slide > div {
  position: relative;
  margin-top: 1rem;

  @include media-breakpoint-down('sm') {
    position: static;
  }
}

.image-gallery-image {
  display: block;
  width: auto !important;
  max-width: 100%;
  height: 80vh;
  max-height: 33rem !important;
  object-fit: contain !important;

  @include media-breakpoint-down('sm') {
    max-height: none !important;
  }
}

.image-gallery-slide {
  display: flex;
  justify-content: center;
  outline: 0;
}

.image-gallery-slide .image-gallery-description {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--color-caption);
  text-align: left;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(2px);

  @include media-breakpoint-down('sm') {
    text-align: center;
  }
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  z-index: 10;
  position: absolute;
  padding: 0.25rem;
  font-size: 1.875rem;
  color: var(--color-accent);
  outline: 0;
  background: none;
  border: none;
  cursor: pointer;
  transition: background var(--transition-timing) var(--transition-easing);

  @include media-breakpoint-down('sm') {
    opacity: 0.4;
  }

  &:focus {
    outline: 2px solid var(--color-accent);
  }

  &:hover {
    background: var(--color-bg);
  }
}

.image-gallery-left-nav {
  margin-left: -3.5rem;

  @include media-breakpoint-down('sm') {
    margin-left: 0;
  }
}

.image-gallery-right-nav {
  margin-right: -3.5rem;

  @include media-breakpoint-down('sm') {
    margin-right: 0;
  }
}
