@import '../../scss/variables';

.blockquote-block {
  display: flex;
  justify-content: center;
}

blockquote {
  --corner-size: 2.5rem;

  @include media-breakpoint-down('lg') {
    font-size: 1.375rem;
    line-height: 2rem;
  }

  @include media-breakpoint-down('sm') {
    --corner-size: 1rem;

    width: calc(100% - 2rem);
    margin: 3rem auto;
    padding: 1rem;
  }

  display: block;
  position: relative;
  width: calc(100% - 5rem);
  max-width: 53rem;
  margin: var(--corner-size) auto;
  padding: 6rem;
  font-size: 1.625rem;
  font-style: italic;
  line-height: 2rem;
  border: 2px solid var(--color-accent);
}

.blockquote-block__corner {
  --corner-size: 2.5rem;

  @include media-breakpoint-down('sm') {
    --corner-size: 1rem;
  }

  display: block;
  position: absolute;
  width: var(--corner-size);
  height: var(--corner-size);
  border: 2px solid var(--color-accent);
  pointer-events: none;

  &--1 {
    right: 100%;
    bottom: 100%;
  }

  &--2 {
    left: 100%;
    bottom: 100%;
  }

  &--3 {
    left: 100%;
    top: 100%;
  }

  &--4 {
    right: 100%;
    top: 100%;
  }
}
