@import '../../scss/variables';

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-2rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.field__error {
  display: inline-block;
  margin: 0.25rem 0;
  color: var(--color-error);
  font-feature-settings: 'pnum' on, 'lnum' on;
  animation: slideIn var(--transition-timing) var(--transition-easing);

  @include media-breakpoint-down('sm') {
    width: 100%;
  }
}
