@import '../../scss/variables';

header {
  z-index: 10;
  top: 0;
  position: absolute;
  width: 100%;

  @include media-breakpoint-down('sm') {
    position: fixed;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 1.75rem;
  padding-bottom: 1.25rem;
  transition: background var(--transition-timing) var(--transition-easing),
    box-shadow var(--transition-timing) var(--transition-easing);

  @include media-breakpoint-down('sm') {
    justify-content: flex-end;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &.nav--page-scrolled,
    .header--dark & {
      background: var(--color-bg);
    }

    &.nav--page-scrolled {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    }
  }

  @include media-breakpoint-up('md') {
    &.container__inner {
      position: static;
      max-width: 102rem;
      padding-left: 6rem;
      padding-right: 6rem;
    }
  }
}

.nav__logo {
  transition: none;

  svg {
    width: auto;
    height: 3rem;
  }

  .header--light & {
    color: var(--color-text-light);

    &:hover,
    &:focus {
      color: var(--color-accent);
      transition: var(--transition-combined);
    }
  }

  .header--dark & {
    color: var(--color-primary);

    &:hover,
    &:focus {
      color: var(--color-accent);
      transition: var(--transition-combined);
    }
  }

  @include media-breakpoint-down('sm') {
    .nav--page-scrolled & {
      color: var(--color-primary);
    }
  }
}

.nav__language {
  position: absolute !important;
  right: 2rem;

  @include media-breakpoint-down('sm') {
    display: none;
  }

  @media (min-width: 1920px) {
    position: relative !important;
    right: -8.5rem;
  }

  .header--hide-desktop-drawer & {
    position: relative !important;
    right: 0;
  }
}
