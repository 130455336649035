@import '../../scss/variables';

$menu-transition: transform 0.2s ease-in-out, background 0.2s ease-in-out;
$menu-toggle-transition: transform 0.1s ease-in-out, background var(--transition-timing) var(--transition-easing);
$menu-transition-mobile: transform 0.2s ease-in-out;

.drawer {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  color: var(--color-text-light);
  background: var(--color-menu-bg);
  transition: $menu-transition;
  transform: translateX(-100%);

  @include media-breakpoint-down('sm') {
    background: transparent;
    transform: none;
    pointer-events: none;
  }

  &--open {
    transform: translateX(0);

    @include media-breakpoint-down('sm') {
      transform: none;
      pointer-events: auto;
      background: var(--color-primary);
    }
  }
}

.drawer__overflow {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  .drawer--has-overflow & {
    overflow: auto;
  }

  @include media-breakpoint-down('sm') {
    overflow: auto;
  }
}

.drawer__inner {
  min-height: calc(100% - 4.25rem);
  padding-top: 2.5rem;
  padding-bottom: 2rem;

  @include media-breakpoint-down('sm') {
    min-height: 100%;
    padding-top: 5rem;
    padding-bottom: 2rem;
    background: var(--color-primary);
    transform: translateY(-100%);
    transition: $menu-transition-mobile;

    .drawer--open & {
      transform: translateY(0);
    }
  }
}

.drawer__top {
  z-index: 11;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1.75rem;
  transition: all 50ms var(--transition-easing);

  @include media-breakpoint-down('sm') {
    position: fixed;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    height: 5.125rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    transition-delay: 150ms;
    pointer-events: none;

    .drawer--open & {
      background: var(--color-primary);
      transition-delay: 0ms;
      pointer-events: auto;
    }
  }
}

.drawer__description {
  color: var(--color-gray-dark);
}

.drawer__toggle-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.drawer__h2 {
  margin-top: 6rem;
  margin-bottom: 3.5rem;

  @include media-breakpoint-down('sm') {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }
}

.drawer__languages {
  @include media-breakpoint-down('sm') {
    opacity: 0;
    transition: all 50ms var(--transition-easing);
    transition-delay: 150ms;

    .drawer--open & {
      pointer-events: auto;
      opacity: 1;
      transition-delay: 0ms;
    }
  }
}

.drawer__toggle {
  z-index: 11;
  position: fixed;
  width: 4rem;
  height: 100vh;
  margin-top: 0;
  margin-left: -4rem;
  transform: translateX(100%);
  transition: $menu-toggle-transition;
  color: var(--color-text-light);
  background: var(--color--menu-bg-opacity);
  outline: 0 !important;

  .header--hide-desktop-drawer .drawer:not(.drawer--open) & {
    display: none;
  }

  @include media-breakpoint-down('sm') {
    display: none;
  }

  &:hover {
    background: var(--color-menu-bg);
  }

  &:focus {
    border-color: var(--color-accent);
  }

  .drawer--open & {
    background: var(--color-accent);
    transform: translateX(0);

    &:hover {
      background: var(--color-accent-dark);
    }

    &:focus {
      border-color: var(--color-bg);
    }
  }
}

.drawer__toggle-text {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  transition: $menu-transition;
  white-space: nowrap;

  &--open {
    opacity: 0;

    .drawer--open & {
      opacity: 1;
    }
  }

  &--closed {
    opacity: 1;

    .drawer--open & {
      opacity: 0;
    }
  }
}

.drawer__icon {
  width: 1.5em;
  min-width: 1.5em;
  margin-left: 1em;
  color: var(--color-accent);
  transform: scaleY(-1);
  transition: $menu-transition;

  .drawer--open & {
    transform: scaleY(1);
    color: currentColor;
  }
}

.drawer__burger {
  z-index: 11;
  position: relative;
  margin-left: -0.5rem;
  padding: 0 0.5rem;
  border: none;
  background: none;
  height: 3rem;
  outline: 0;
  cursor: pointer;
  pointer-events: auto;

  @include media-breakpoint-up('md') {
    display: none;
  }

  & > span {
    display: block;
    position: relative;
    width: 2rem;
    height: 2px;
    background-color: var(--color-text-light);
    transition: var(--transition-combined);

    &:before,
    &:after {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--color-text-light);
      transition: var(--transition-combined);
      content: '';
    }

    &:before {
      transform: translateY(-500%);
    }

    &:after {
      transform: translateY(500%);
    }
  }
}

.drawer__group-link {
  color: var(--color-gray-dark);
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--color-white);
  }
}

.drawer__title-container,
.drawer__group:not(:last-child) {
  &:after {
    display: block;
    width: 100%;
    height: 14px;
    margin: 3rem 0;
    background: url('../../assets/drawer_zig.svg');
    background-size: 40px 14px;
    background-repeat: round;
    content: '';

    @include media-breakpoint-down('sm') {
      margin: 2rem 0;
      background-size: 24px 14px;
    }
  }
}

.drawer__title {
  color: var(--color-white);
  font-weight: bold;

  @include media-breakpoint-down('sm') {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.drawer__displays {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 4rem;
  margin-top: 1rem;

  @include media-breakpoint-down('md') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down('sm') {
    margin-top: 0.5rem;
    grid-template-columns: repeat(1, 1fr);
  }
}

.drawer__links {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.drawer__link:not(:first-child) {
  margin-top: 1rem;

  @include media-breakpoint-down('sm') {
    margin-top: 0.5rem;
  }
}

.drawer--open .drawer__burger > span {
  background-color: transparent;

  &:before,
  &:after {
    background-color: var(--color-red-dark);
  }

  &:before {
    transform: translateY(0) rotate(30deg);
  }

  &:after {
    transform: translateY(0) rotate(-30deg);
  }
}

.page-scrolled,
.header--dark {
  .drawer__burger > span {
    background-color: var(--color-primary);

    &:before,
    &:after {
      background-color: var(--color-primary);
    }
  }

  .drawer--open .drawer__burger > span {
    background-color: transparent;

    &:before,
    &:after {
      background-color: var(--color-red-dark);
    }

    &:before {
      transform: translateY(0) rotate(30deg);
    }

    &:after {
      transform: translateY(0) rotate(-30deg);
    }
  }
}

@keyframes drawerFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
