@import '../../scss/variables';

$duration: 1.5s;

.loader {
  height: 100%;
  font-size: 2rem;
  color: var(--color-primary);
  animation: fadeLoader 200ms ease-out;
  animation-fill-mode: forwards;

  &--inverted {
    color: var(--color-white);
  }

  &--center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--overlay {
    z-index: 5;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  &--fixed {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--backdrop {
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      content: '';
      opacity: 0.5;
    }
  }

  &--backdrop-white {
    &:before {
      background: var(--color-white);
    }
  }

  &--container-sm {
    min-height: 10rem;

    @include media-breakpoint-down('sm') {
      min-height: 5rem;
    }
  }

  &--container-md {
    min-height: 20rem;

    @include media-breakpoint-down('sm') {
      min-height: 10rem;
    }
  }

  &--container-lg {
    min-height: 30rem;

    @include media-breakpoint-down('sm') {
      min-height: 15rem;
    }
  }
}

.loader__inner {
  position: relative;
  animation-name: hourglassRotate;
  animation-duration: $duration;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.loader__sand {
  position: absolute;
  left: 1%;
  top: 1%;
  right: 1%;
  bottom: 1%;
  clip-path: polygon(0 0, 100% 0, 50% 50%, 100% 100%, 0 100%, 50% 50%);

  &:before,
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    content: '';
    background: currentColor;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &:before {
    bottom: 50%;
    animation-name: hourglassTop;
  }

  &:after {
    bottom: 0;
    top: auto;
    animation-name: hourglassBottom;
  }
}

.loader__icon {
  width: 1em;
  height: auto;
  stroke: currentColor;
}

@keyframes fadeLoader {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hourglassTop {
  0% {
    height: 49%;
  }

  75% {
    height: 0;
  }

  100% {
    height: 0;
  }
}

@keyframes hourglassBottom {
  0% {
    height: 0;
  }

  75% {
    height: 49%;
  }

  100% {
    height: 49%;
  }
}

@keyframes hourglassRotate {
  0% {
    transform: rotate(0);
  }

  75% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}
