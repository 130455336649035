@import '../../scss/variables';

.large-image-block {
  max-width: none;
  margin-left: -6rem;
  margin-right: -6rem;

  @include media-breakpoint-down('sm') {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

.large-image-block__image {
  display: block;
  width: 100%;
  object-fit: cover;
}

.large-image-block__figure {
  margin: 0;
}

.large-image-block__description {
  max-width: 78rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 6rem;
  text-align: center;

  @include media-breakpoint-down('sm') {
    padding: 0 1rem;
    text-align: left;
  }
}
