@import '../../scss/variables';

.field__group {
}

.field__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.75rem;
}
