@import '../../../../scss/variables';

.banner {
  z-index: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 90vh;
  max-height: 53rem;
  min-height: 40rem;
  background: var(--color-bg);
  background-image: url('../../../../assets/banner_x1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  @include media-retina() {
    background-image: url('../../../../assets/banner_x2.jpg');
  }

  @include media-breakpoint-down('sm') {
    height: 100vh;
    min-height: 34rem;
    background-image: url('../../../../assets/banner_mobile_x1.jpg');

    @include media-retina() {
      background-image: url('../../../../assets/banner_mobile_x2.jpg');
    }
  }

  @include media-breakpoint-up('xl') {
    background-position: center;
  }

  &:before {
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: var(--color-primary);
    opacity: 0.2;
    content: '';
  }

  &:after {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2rem;
    background-image: url('../../../../assets/banner_zig.svg');
    background-position: center top;
    content: '';
  }
}

.banner__inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1 1px;
  width: 100%;
  color: var(--color-text-light);
  max-width: 102rem;
  margin: 0 auto;
}

.banner__title {
  display: inline-block;
  position: relative;
  max-width: 37rem;
  margin-top: 0;
  margin-bottom: 15rem;
  padding: 2rem 4rem;
  text-align: center;
  line-height: 6rem;
  border-top: 3px solid var(--color-text-light);
  border-bottom: 3px solid var(--color-text-light);

  @include media-breakpoint-down('sm') {
    align-self: center;
    max-width: 25rem;
    margin-bottom: 12rem;
    padding: 2rem;
    line-height: 3rem;
  }
}

.banner__corner {
  --corner-size: 2.5rem;

  @include media-breakpoint-down('sm') {
    --corner-size: 1.5rem;
  }

  display: block;
  position: absolute;
  width: var(--corner-size);
  height: var(--corner-size);
  border: 3px solid var(--color-text-light);
  pointer-events: none;

  &--1 {
    left: 0;
    bottom: 100%;
  }

  &--2 {
    right: 0;
    bottom: 100%;
  }

  &--3 {
    right: 0;
    top: 100%;
  }

  &--4 {
    left: 0;
    top: 100%;
  }
}

.banner__icon {
  position: absolute;
  left: 50%;
  top: calc(100% + 2.5rem);
  transform: translateX(-50%);
  width: 6rem;
  height: auto;

  @include media-breakpoint-down('sm') {
    width: 5rem;
  }
}
