@import 'mixins';

/* Breakpoints */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: 1280px,
  xl: 1441px,
);

// animation time for digiblock fullscreen
$digiTime: 400;

// to be used in js
//noinspection CssInvalidPseudoSelector,CssUnknownProperty
:export {
  mobileBreakpoint: map-get($grid-breakpoints, 'md');
  tabletBreakpoint: map-get($grid-breakpoints, 'lg');
  digiTime: $digiTime;
}
