@import '../../scss/variables';
@import '../../scss/mixins';

/* TOAST OVERRIDES */
.Toastify__toast-container {
  z-index: 13;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 6rem;
  transition: transform 200ms;
  transform: translateY(0);
  transition-timing-function: var(--transition-timing);
  pointer-events: none;

  @include media-breakpoint-down('sm') {
    bottom: -0.5rem;
    padding: 0;
  }
}

.Toastify__toast-container--bottom-center {
  margin-left: 0;
  transform: translateX(-50%);

  @include media-breakpoint-down('sm') {
    left: 0;
    transform: none;
  }
}

.Toastify__toast {
  max-width: 66rem;
  min-height: 0;
  margin-bottom: 0.5rem;
  padding: 1rem 4.5rem 1rem 1.5rem;
  font-family: inherit;
  border-radius: 0;
  background: var(--color-bg);
  color: var(--color-text);
  pointer-events: auto;

  @include media-breakpoint-down('sm') {
    width: 100%;
  }

  &--success {
    color: var(--color-text-light);
    background-color: var(--color-success);
  }

  &--warning {
    color: var(--color-text-light);
    background-color: var(--color-warning);
  }

  &--error {
    color: var(--color-text-light);
    background-color: var(--color-error);
  }
}

.Toastify__progress-bar {
  height: 0.25rem;
  background-color: rgba(0, 0, 0, 0.2);
}

.Toastify__close-button {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    background: none;
  }
}
