@import '../../scss/variables';

.close-button {
  z-index: 11;
  padding: 0 0.25rem;
  border: none;
  background: none;
  height: 2rem;
  outline: 0;
  cursor: pointer;
  transition: background var(--transition-timing) var(--transition-easing);

  &:focus {
    outline: 2px solid var(--color-accent);
  }

  &:hover {
    background: var(--color-bg);
  }

  &--inverted {
    & > span:before,
    & > span:after {
      background-color: var(--color-text-light) !important;
    }
  }

  & > span {
    display: block;
    position: relative;
    width: 2rem;
    height: 2px;
    background-color: transparent;
    transition: var(--transition-combined);

    &:before,
    &:after {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--color-red-dark);
      transition: var(--transition-combined);
      content: '';
    }

    &:before {
      transform: translateY(0) rotate(30deg);
    }

    &:after {
      transform: translateY(0) rotate(-30deg);
    }
  }
}
