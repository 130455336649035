@import '../../scss/variables';

.hash-modal__ticket {
  display: block;
  //margin: -0.4rem 2rem 1rem 0;
  width: 12rem;
  object-fit: cover;
}

.hash-modal__flow {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;

  @include media-breakpoint-down('sm') {
    flex-direction: column;
    gap: 0;

    & > * {
      margin-top: 0;
    }
  }
}
