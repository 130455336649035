@import '../../scss/variables';

.text-block__inner {
  display: flex;
  margin-top: 1em;
  overflow: hidden;

  @include media-breakpoint-down('sm') {
    flex-direction: column;
  }

  .text-block--right & {
    flex-direction: row-reverse;

    @include media-breakpoint-down('sm') {
      flex-direction: column-reverse;
    }
  }
}

.text-block__image {
  flex: 1 1 55%;
  max-width: 37rem;
  margin: 0;

  @include media-breakpoint-down('lg') {
    max-width: 33rem;
  }

  @include media-breakpoint-down('sm') {
    margin-top: 1em;
    max-width: none;
  }

  .text-block--left & {
    margin-right: 4rem;

    @include media-breakpoint-down('sm') {
      margin-right: 0;
    }
  }

  .text-block--right & {
    margin-left: 4rem;

    @include media-breakpoint-down('sm') {
      margin-left: 0;
    }
  }

  img {
    width: 100%;
  }
}

.text-block__content {
  flex: 1 1 45%;

  & > *:first-child {
    margin-top: 0;
  }

  @include media-breakpoint-down('sm') {
    .text-block--left & > *:first-child {
      margin-top: 1em;
    }
  }
}
