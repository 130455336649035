@import '../../scss/variables';

.video-block {
  position: relative;

  &--large {
    max-width: none;
    margin-left: -6rem;
    margin-right: -6rem;

    @include media-breakpoint-down('sm') {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }

  h2 + .video {
    margin-top: 1em;
  }
}

.video-block__description {
  max-width: 78rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 6rem;
  text-align: center;

  @include media-breakpoint-down('sm') {
    padding: 0 1rem;
    text-align: left;
  }
}
