* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html {
  font-size: var(--font-size);
}

body {
  min-width: 320px;
  margin: 0;
  background: var(--color-bg);
  overflow-x: hidden;
  color: var(--color-text);
  font: var(--font-weight) 1.375rem / var(--line-height) var(--font-family);

  @include media-breakpoint-down('lg') {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}

a,
.link {
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  color: var(--color-text);
  transition: var(--transition-combined);
  border: none;
  background: none;
  outline: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--color-accent);
  }
}

svg {
  display: block;
  width: var(--icon-size);
  height: var(--icon-size);
  fill: currentColor;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
table,
figcaption {
  margin: 1em 0 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0.5em 0;
}

ul {
  padding-left: 1.3em;
  overflow: hidden;
  list-style: none;

  &:not(.list--unstyled) {
    & > li {
      position: relative;

      &:before {
        position: absolute;
        left: -1em;
        transform: rotate(-90deg);
        font-size: 1.2em;
        content: '♢';
      }
    }
  }
}

ol {
  overflow: hidden;
  font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
}

.list--unstyled {
  padding: 0;
  list-style: none;
}

.list--inline {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  > li {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

/* Disable all animations on the site
 Useful when you don't want the animations to play when user resizes the screen on changes orientation
*/
body.disable-scroll * {
  transition: none !important;
}

/* Exception rules (use it with caution):
   * Note: We are using REMs over EMs to enforce consistent spacing across the app. For less/more space use other mt-(n). */
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}

.atlwdg-trigger {
  z-index: 9;
}
