@import '../../../../scss/variables';

@keyframes displayFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.display-link {
  display: flex;
  flex-direction: column;
  color: var(--color-accent-light);
  text-decoration: none;
  opacity: 0;
  animation-name: displayFadeIn;
  animation-fill-mode: forwards;
  animation-duration: 400ms;
  animation-timing-function: ease-out;
  animation-delay: calc(var(--drawer-animation-order) * 50ms);
  font-size: 1.75rem;
  line-height: 2rem;

  @include media-breakpoint-down('lg') {
    font-size: 1.375rem;
    line-height: 2rem;
  }

  @include media-breakpoint-down('sm') {
    padding: 0.25rem 0;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  &:visited {
    color: var(--color-blue);
  }

  &.active {
    color: var(--color-white);
  }

  &:hover,
  &:focus {
    color: var(--color-white);
  }
}
