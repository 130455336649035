@import '../../../../scss/variables';

.steps {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  margin-bottom: -3rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  @include media-breakpoint-down('sm') {
    margin-left: 0;
    margin-right: 0;
  }

  &:before {
    position: absolute;
    top: 40%;
    left: 50%;
    content: url('../../../../assets/steps_separator.svg');
    transform: translate(-50%, -50%);

    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.steps__item {
  flex: 0 1 calc(33% - 3rem);
  min-width: 15rem;
  margin: 0 6rem 3rem;

  @media (max-width: 1024px) {
    flex: 0 1 calc(50% - 3rem);
  }

  @include media-breakpoint-down('sm') {
    flex: 0 1 100%;
  }
}

.steps__figure {
  display: block;
  margin: 0;
}

.steps__ratio {
  position: relative;
  width: 100%;
  height: 0;
  margin: 0 auto;
  padding-bottom: 100%;
  overflow: hidden;
}

.steps__ratio-container {
  position: relative;
  max-width: 15rem;
  margin: 0 auto;
}

.steps__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.steps__caption {
  margin-top: 2rem;
  text-align: center;
}

.steps__buttons {
  display: flex;
  justify-content: center;

  .btn {
    margin-top: 6rem;
    margin-bottom: 5rem;

    @include media-breakpoint-down('sm') {
      margin-top: 4rem;
      margin-bottom: 0;
    }
  }
}
