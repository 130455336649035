label {
  display: inline-block;
  padding: 0.25rem 0.5rem;
}

textarea {
  width: 37rem;
  min-height: 20rem;
  resize: none;

  @include media-breakpoint-down('sm') {
    min-height: 10rem;
  }
}

.field__container {
  display: flex;
  flex-wrap: wrap;
}

input,
.input,
textarea {
  display: block;
  width: 25rem;
  min-width: 0;
  margin-bottom: 0.25rem;
  padding: calc(0.75rem + 1px) 1rem calc(0.5rem + 1px);
  font: var(--font-weight) 1.375rem / var(--line-height) var(--font-family);
  font-feature-settings: 'pnum' on, 'lnum' on;
  border: 1px solid var(--color-black);
  background: none;
  outline: none;
  transition: var(--transition-combined);

  &:focus {
    border: 1px solid var(--color-accent);
    box-shadow: inset 0 0 0 1px var(--color-accent);
  }

  &[invalid],
  &:invalid,
  &.invalid {
    border: 1px solid var(--color-error);
    box-shadow: inset 0 0 0 1px var(--color-error);
  }

  @include media-breakpoint-up('md') {
    .field__inner > & {
      margin-right: 2rem;
    }
  }
}

.input--x-small {
  width: 6rem;
}

.input--small {
  width: 7.5rem;
}

.input--large {
  width: 37rem;
}

/* DEFAULT OVERRIDES */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
