@import '../../scss/variables';

.gdpr-bar {
  z-index: 12;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-primary);
  transition: var(--transition-combined);
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);

  &--hidden {
    transform: translateY(100%);
  }
}

.gdpr-bar__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 120rem;
  margin: 0 auto;
  padding: 0.5rem 6rem;

  @include media-breakpoint-down('sm') {
    padding: 0.5rem 1rem;
  }
}

.gdpr-bar__text {
  margin-top: 0;
  padding-right: 1rem;
  color: var(--color-white);
}

.gdpr-bar__buttons {
  display: flex;
  align-items: center;

  @include media-breakpoint-down('xs') {
    flex-wrap: wrap;
    justify-content: center;
  }

  .btn {
    margin-top: 0;
    white-space: nowrap;

    &:not(:first-child) {
      @include media-breakpoint-up('sm') {
        margin-left: 1rem;
      }

      @include media-breakpoint-down('xs') {
        margin-top: 0.5rem;
      }
    }
  }
}
.gdpr-bar__link {
  color: inherit;
}
