.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 6rem);
  margin-bottom: 6rem;

  @include media-breakpoint-down('sm') {
    margin-bottom: 3rem;
  }
}

.container__full {
  max-width: 102rem;
  margin: 0 auto;
  background: rgba(blue, 0.7);
}

.container__main {
  margin-top: 6rem;

  @include media-breakpoint-down('sm') {
    margin-top: 7rem;
  }
}

.container__wrapper {
  max-width: 102rem;
  margin: 0 auto;
  padding: 0 6rem;

  @include media-breakpoint-down('sm') {
    padding: 0 1rem;
  }
}

.container__inner {
  position: relative;
  max-width: 102rem;
  margin: 0 auto;
  padding: 0 6rem;

  @include media-breakpoint-down('sm') {
    padding: 0 1rem;
  }

  &--condensed {
    max-width: 80rem;
  }

  &--pull-right {
    padding-right: 2rem !important;

    @include media-breakpoint-down('sm') {
      padding-right: 1rem !important;
    }
  }
}

section,
.section {
  max-width: 90rem;
  margin: 6rem auto 0;

  @include media-breakpoint-down('sm') {
    margin-top: 3rem;
  }
}

.container__inner--display > section {
  margin-top: 4rem;

  @include media-breakpoint-down('sm') {
    margin-top: 3rem;
  }
}

.section--pull-left {
  margin-left: 0;
}

.text--limit {
  max-width: 58rem;
}

.text--limit-lg {
  max-width: 30rem;
}
