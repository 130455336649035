@import '../../scss/variables';

.gallery-block__items {
  display: grid;
  margin-top: 1em;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 3fr));
  grid-gap: 1rem;
}

.gallery-block__button {
  display: block;
  align-self: flex-start;
  position: relative;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: 0;
}

.gallery-block__figure {
  display: block;
  margin: 0;
}

.gallery-block__ratio {
  position: relative;
  width: 100%;
  height: 0;
  padding: 0 0 100%;
  overflow: hidden;

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: var(--color-hover-overlay);
    transition: var(--transition-combined);
    content: '';
  }

  .gallery-block__button:hover &:after,
  .gallery-block__button:focus &:after {
    opacity: 1;
  }
}

.gallery-block__thumbnail {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-block__show-more {
  display: block;
  margin: 1em auto 0;
}
