@import '../../scss/variables';

.display-navigation {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  max-width: 102rem;
  margin: 6rem auto 0;
  padding-left: 6rem;
  padding-right: 2rem;

  @include media-breakpoint-down('sm') {
    margin-top: 3rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.display-navigation__link {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--color-accent);
  text-decoration: none;
  font-size: 1.5rem;
  line-height: 1.5;
  --icon-size: 1.2em;

  &:last-child {
    text-align: right;
  }

  &:nth-child(2) {
    position: absolute;
    right: 2rem;

    @include media-breakpoint-down('sm') {
      position: relative;
      right: 0;
    }

    @media (min-width: 1920px) {
      position: relative;
      right: -8.5rem;
    }
  }

  &:hover,
  &:focus {
    color: var(--color-accent-dark);
  }

  @include media-breakpoint-down('sm') {
    font-size: 0.875rem;
    --icon-size: 1.2em;
  }
}
