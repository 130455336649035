.modal__close {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

/** REACT MODAL OVERRIDES */
.ReactModal__Content,
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.25s, transform 0.3s;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
    pointer-events: none;
  }
}

.ReactModal__Content {
  transform: scale(0.95);

  @include media-breakpoint-down('sm') {
    position: fixed !important;
    padding-top: 3rem !important;
    height: auto !important;
    bottom: 0 !important;
  }

  &--after-open {
    transform: scale(1);
  }

  &--before-close {
    transform: scale(0.95);
  }
}
