@import '../../scss/variables';

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.25rem;
  font-size: 1rem;

  @include media-breakpoint-down('sm') {
    margin-top: 0;
  }
}

.breadcrumbs__item {
  line-height: 1.5rem;

  &:not(:last-child) {
    &:after {
      margin: 0 0.5rem;
      content: '→';
    }
  }
}

.breadcrumbs__link {
  padding: 0;
  white-space: nowrap;
  text-decoration: none;
}
