:root {
  /* Colors */
  --color-primary: #002a41;
  --color-primary-rgb: 0, 42, 65;
  --color-accent: #00a891;
  --color-accent-dark: #008976;
  --color-accent-light: #00d2aa;
  --color-blue: #00aeef;
  --color-red: #e8155b;
  --color-red-dark: #e8155b;
  --color-gray: #e9e9e9;
  --color-gray-dark: #bdbdbd;
  --color-black: #000000;
  --color-white: #ffffff;

  --color-text: var(--color-black);
  --color-text-light: var(--color-gray);
  --color-caption: #4f4f4f;
  --color-bg: var(--color-gray);
  --color-bg-rgb: 233, 233, 233;
  --color--menu-bg-opacity: rgba(var(--color-primary-rgb), 0.8);
  --color-menu-bg: rgba(var(--color-primary-rgb), 1);
  --color-hover-overlay: rgba(var(--color-primary-rgb), 0.2);
  --color-modal-bg: var(--color-white);
  --color-success: var(--color-accent);
  --color-error: var(--color-red);
  --color-warning: var(--color-red);

  /* Typography */
  --font-size: 16px;
  --line-height: 2rem;
  --font-weight: 400;
  --font-family: Aestii, sans-serif;
  --font-family-superscript: IBM Plex Sans, sans-serif;

  /* Transition */
  --transition-timing: 60ms;
  --transition-easing: linear;
  --transition-combined: all var(--transition-timing) var(--transition-easing);

  /* Icons */
  --icon-size: 1em; // font-size * line-height (helps to align icons correctly and consistently)
}
