@import '../../scss/variables';

.file-list__list {
  padding-left: 2rem;
  list-style: none;
  counter-reset: file;
  overflow: visible;
}

.file-list__item {
  display: flex;
  position: relative;
  max-width: 47rem;
  margin-bottom: 1rem;

  @include media-breakpoint-down('sm') {
    flex-direction: column;
  }

  &:before {
    position: absolute;
    top: 0;
    right: calc(100% + 1rem);
    counter-increment: file;
    content: counter(file);
    line-height: 1;
  }
}

.file-list__content {
  padding-right: 4rem;
  color: var(--color-text);
}

.file-list__change {
  display: block;
  margin-top: 0.5rem;
  padding-left: 0;
}

.file-list__remove {
  position: absolute;
  right: 0;
  top: -0.25rem;
}

.file-list__thumb {
  display: block;
  width: 5.5rem;
  height: 5.5rem;
  margin-right: 1rem;
  object-fit: cover;
  font-size: 0.75rem;
  line-height: 1;
  overflow: hidden;

  @include media-breakpoint-down('sm') {
    display: inline-block;
    vertical-align: middle;
  }
}

.file-list__name {
  margin-top: 0;

  @include media-breakpoint-down('sm') {
    margin-top: 1em;
  }
}

.file-list__description {
  @include media-breakpoint-down('sm') {
    margin-top: 1em;
  }
}

.file-list__author {
  margin-top: 0.5rem;
}
