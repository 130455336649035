@import '../../scss/variables';

.image-comparison-block__items {
  display: grid;
  margin-top: 1em;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 3fr));
  grid-gap: 3rem;

  @include media-breakpoint-down('sm') {
    grid-template-columns: repeat(auto-fill, minmax(15rem, 3fr));
  }
}

.image-comparison-block__figure {
  display: block;
  margin: 0;
}

.image-comparison-block__ratio {
  position: relative;
  width: 100%;
  height: 0;
  padding: 0 0 calc(100% / 0.7);
  overflow: hidden;
}

.image-comparison-block__thumbnail {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
