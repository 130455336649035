@import '../../scss/variables';

.file-uploader__file-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 1px;

  &:focus + label {
    outline: 2px solid var(--color-accent);
  }
}

.file-uploader__buttons {
  display: flex;
  flex-wrap: wrap;

  & > .btn {
    margin-right: 1rem;
  }
}

.file-uploader__error {
  align-self: flex-end;
  margin: 0;
  padding: 0.625rem 0;
}

.file-uploader__content {
  display: flex;
  margin-top: 0.75rem;

  @include media-breakpoint-down('sm') {
    flex-direction: column;
  }
}

.file-uploader__image {
  width: 15rem;
  min-width: 15rem;
  height: 15rem;
  margin-top: 0;
  margin-right: 1rem;

  @include media-breakpoint-down('sm') {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: var(--color-bg);
  }
}

.file-uploader__description {
  min-height: 0;
}
