@import '../../scss/variables';

.page-not-found {
  margin-bottom: 0;
  height: 100vh;
  //max-height: 44rem;
  min-height: 0;

  @include pattern-background();
}

.page-not-found__main,
.page-not-found__inner {
  height: 100%;
  margin-top: 0;
}

.page-not-found__section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  height: 100%;
}

.page-not-found__block {
  position: relative;
  padding: 4rem 6rem;
  color: var(--color-primary);
  text-align: center;
  border-top: 3px solid currentColor;
  border-bottom: 3px solid currentColor;

  @include media-breakpoint-down('sm') {
    padding: 2rem;
  }

  @media (orientation: landscape) {
    @media (max-height: 500px) {
      padding: 2rem;
    }
  }
}

.page-not-found__title {
  margin-top: 0;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.page-not-found__corner {
  --corner-size: 2.5rem;

  @include media-breakpoint-down('sm') {
    --corner-size: 1.5rem;
  }

  @media (orientation: landscape) {
    @media (max-height: 500px) {
      --corner-size: 1.5rem;
    }
  }

  display: block;
  position: absolute;
  width: var(--corner-size);
  height: var(--corner-size);
  border: 3px solid currentColor;
  pointer-events: none;

  &--1 {
    left: 0;
    bottom: 100%;
  }

  &--2 {
    right: 0;
    bottom: 100%;
  }

  &--3 {
    right: 0;
    top: 100%;
  }

  &--4 {
    left: 0;
    top: 100%;
  }
}
