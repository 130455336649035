@import '../../scss/variables';

.checkbox {
  position: relative;
  margin-top: 0;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.checkbox__input {
  width: 1px;
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.checkbox__label {
  display: flex;
  position: relative;
  align-items: center;
  min-height: 2.5rem;
  margin-top: 0;
  font-size: 1em;
  color: var(--color-text);

  &:before {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    border: 1px solid var(--color-black);
    transition: var(--transition-combined);
    content: '';
    box-sizing: border-box;

    .checkbox--radio & {
      border-radius: 50%;
    }
  }

  &:after {
    display: block;
    position: absolute;
    top: 50%;
    left: calc(0.5rem + 2px);
    height: 0.75rem;
    width: 0.75rem;
    transition: var(--transition-combined);
    transform: translateY(-50%) scale(0.8);
    background: url('../Icon/Icons/tick.svg') no-repeat;
    background-position: center;
    opacity: 0;
    content: '';

    .checkbox--radio & {
      left: 0.75rem;
      background: var(--color-accent);
      border-radius: 50%;
      height: 0.5rem;
      width: 0.5rem;
    }
  }

  .checkbox__input:focus + &:before {
    border-color: var(--color-accent);
  }

  .checkbox__input:checked + &:after {
    transform: translateY(-50%) scale(1);
    opacity: 1;
  }
}
