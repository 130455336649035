@import '../../scss/variables';

.video {
  position: relative;
  height: 0;
  padding-bottom: calc(100% * 0.5625); // keep 16:9 ratio
}

.video__iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
}
